import * as wecom from '@wecom/jssdk'
import request from '@/tools/request'
import session from '@/tools/session'
import data from '@/tools/data'
import { Notify } from 'vant';
import { Toast } from 'vant';

export default {
  wecom,
  check_agent,
  get_agent,
  login
}

const jsApiList = [
  'getContext',
  'getCurExternalContact',
  'selectExternalContact',
  'selectEnterpriseContact',
  'sendChatMessage'
]

export async function check_agent() {
  console.log('check_agent')
  await wecom.checkJsApi({ jsApiList: jsApiList })
}

export async function get_agent() {
  console.log('get_agent')
  const url = location.href.split("#")[0].split("?")[0]
  return wecom.register({
    corpId: 'ww28471c5a45fa1161',
    agentId: 1000002,
    jsApiList: jsApiList,
    async getAgentConfigSignature(url) {
      const res: any = await request('/work/login/agent', { mod: 'assistant', url: url }, { method: 'POST' })
      const { timestamp, nonceStr, signature } = res.result
      // Notify({ type: 'success', message: '授权成功', })
      return { timestamp, nonceStr, signature }
    },
    onAgentConfigSuccess(){
      console.log('onAgentConfigSuccess')
    }

  })
}

export async function login(url: any) {

  if (!session.get('corp_id')) {
    const params = data.getUrlParams()

    const ticket_body = {
      'corp_id': params.state.replace(url, '').split('@')[0],
      'agent_id': params.state.replace(url, '').split('@')[1],
      "code": params.code
    }

    const ticket_result = await request('/auth/custom/wecom/ticket', ticket_body, { method: 'post', module: 'https://function.meatou.com' })

    if (ticket_result) {

      // await cloud.auth.customAuthProvider().signIn(ticket_result.ticket)

      // session.set('corp_id', ticket_body.corp_id)
      // session.set('agent_id', ticket_body.agent_id)
      // session.set('user_id', ticket_result.userid)
      // session.set('tenant_id', ticket_result.tenant_id)
    }

  }

}



