import axios, { AxiosRequestConfig, Method } from 'axios';
import { Toast } from 'vant';
import config from '../../config.json';


axios.defaults.headers.common = { 'Content-Type': 'application/json;charset=utf8', 'token': '88614826' };

const prefix = ''

axios.defaults.baseURL = process.env.VUE_APP_BASE_API + prefix


// console.log(cloud.auth.hasLoginState())

// axios.interceptors.request.use(
//   config => {
//     const accessToken = token.get()
//     if (accessToken) { config.headers.authorization = accessToken }
//     return config;
//   },
//   error => {
//     Promise.reject(error);
//   }
// );

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('token')
    if (accessToken) { 
      config.headers!.authorization = accessToken
     }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 响应拦截器

axios.interceptors.response.use(
  (response:any) => {

   

    // Some example codes here:
    // code == 20000: success
    // code == 50001: invalid access token
    // code == 50002: already login in other place
    // code == 50003: access token expired
    // code == 50004: invalid user (user not exist)
    // code == 50005: username or password is incorrect
    // You can change this part for your own usage.

    if (response.code !== 20000) {

      switch (response.code) {
        case 50003:
          // ElMessageBox({
          //   title: '您的令牌已经过期',
          //   message: '点击重新登录重新获取令牌',
          //   showCancelButton: false,
          //   confirmButtonText: '重新登录',
          // }).then(() => {
          //   UserModule.LogOut()
          //   location.reload()
          // })

          break;

        default:
          Toast.fail(response.message || '加载失败');
          return Promise.reject(response.message);
          break;
      }

    } else {

      return response
    }

  },
  error => {
    if (process.env.NODE_ENV === 'development') {
      console.log('反馈错误')
      console.log(error)
    }

    // Message({
    //   message: error.message,
    //   type: "error",
    //   duration: 5 * 1000
    // });
    return Promise.reject(error);
  }
);

interface requestOptions {
  method?: Method
  loading?: boolean
  message?: boolean
  module?: string
  upload?: boolean
}

export async function request (url: string, data?: any, options?: requestOptions) {

  // Loading 事件
  if (options && options.loading) { Toast.loading({ message: '加载中...', forbidClick: true, }); }

  const axiosOptions: AxiosRequestConfig = {
    method: 'get'
  }

  // 访问不同模块
  if (options && options.module) {
    axiosOptions.baseURL = options.module
  } else {
    axiosOptions.baseURL = process.env.VUE_APP_BASE_API
  }

  // 拼接 Url
  axiosOptions.url = url

  // 方法处理
  if (options && options.method) {
    axiosOptions.method = options.method
  }

  // 参数处理
  if (data) {
    if (axiosOptions.method === 'get') {
      axiosOptions.params = data
    } else {
      axiosOptions.data = data
    }
  }

  // 上传逻辑
  if (options && options.upload) {
    // axios.defaults.headers = { 'Content-Type': 'multipart/form-data' };
  }

  try {

    const response: any = await axios(axiosOptions)

    if (options?.message) {
      Toast.success(response.message || '加载成功');
    }

    return response

  } catch (error: any) {

    if (options?.message) {
      Toast.fail(error.message || '加载失败');
    }
    return Promise.reject(error);

  } finally {

    if (options && options.loading) { Toast.clear() }

  }

}


export default request
